
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["familyInputs"]

  connect() {
  }

  fire() {
    const selectedCategory = document.querySelector(
      "#formula_size"
    ).value;

    if (selectedCategory === "Familiale") {
      this.familyInputsTarget.classList.remove("d-none");
    } else {
      this.familyInputsTarget.classList.add("d-none");
    }
  }

}