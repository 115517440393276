// Function that allows to display the cookers's choice when clicked. 

const chooseCook = () => {

  const btnAvailbilities = document.querySelectorAll(".time-btn-available")
  const calendar = document.getElementById('booking-calendar')
  if (btnAvailbilities && calendar) {
    btnAvailbilities.forEach((btn) => {

      btn.addEventListener('click', () =>{
        // Si le client a déjà choisi un créneau on le supprime
        const timeChoosen = document.querySelector(".time-choosen")
        if (timeChoosen){
          timeChoosen.classList.remove("time-choosen")
        }

        const choice = event.currentTarget

        choice.classList.add("time-choosen");
        const fillSession = document.getElementById(`${choice.dataset.session}`);
        const date = choice.dataset.date.split('-')
        fillSession.innerText =`le ${date[2]}/${date[1]}/${date[0]} à ${choice.dataset.timeSlot}h avec ${choice.dataset.cookerName}`
        fillSession.dataset.availability = choice.dataset.idAvailability
        fillSession.dataset.session = choice.dataset.cookingSession
      })
    })

  }
}




export {chooseCook}
