import {fetchWithToken} from "../utils/fetchWithToken"
import { csrfToken } from "@rails/ujs";

const checkSessionFilled = (sessionsToCheck) => {
  const check = []
  sessionsToCheck.forEach((session) => {
    (session.innerHTML === "") ? check.push(0) : check.push(1)
  })
  let sum = 0;
  for (let i = 0; i < check.length; i++) {
      sum += check[i];
  }
  return (sum === sessionsToCheck.length) ? true : false
}



// To book a cooker, I filled a hidden form with infos needed in the display_cookers_planning
const bookCookers = () => {

  const buttonCreate = document.getElementById('create-unavailabily')
  if (buttonCreate){

    buttonCreate.addEventListener('click', () => {
     const sessions = document.querySelectorAll(".session-choosen")
     if (sessions) {

       // Check if all sessions are filled.
       if (checkSessionFilled(sessions)) {
        
         const booking = buttonCreate.dataset.booking
         const form = document.getElementById(`edit_booking_${booking}`)
         let n = 1
         sessions.forEach((session) => {
          // Informations nécessaires pour la création d'un cook unavailability, je remplis le formulaire
            let input = document.createElement('input'); 
            input.type = 'hidden'; 
            input.name = `session-${n}`; 
            input.value = `{\"cook_availability\":\"${session.dataset.availability}\", \"cooking_session\": \"${session.dataset.session}\"}`; 
            form.appendChild(input);
            n += 1
         })

         let bookingId = document.createElement('input'); 
         bookingId.type = 'hidden'; 
         bookingId.name = `booking`; 
         bookingId.value = `${booking}`; 
         form.appendChild(bookingId);
  
        // Je soumets le formulaire    
        form.submit()

       } else {
          alert("Merci de choisir un cuisinier pour chacune des séances.")
       }

     }

    }
    )

  }

}


export {bookCookers}
